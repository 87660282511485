/* LoginButton.css */
.login-button {
    margin-right: 0px;
}

.login-button.primary {
    background-color: #1890ff; /* Primary button color */
    border-color: #1890ff;
    color: #fff;
}

.login-button.secondary {
    background-color: #f0f0f0; /* Secondary button color */
    border-color: #d9d9d9;
    color: #333;
}

.login-button.ant-btn.ant-btn-lg {
    border-radius: 0px;
}