.upload-image {
  cursor: pointer;
  align-items: center;
  align-self: stretch;
  background-color: var(--neutral-2);
  border: 1px dashed;
  border-color: var(--neutral-5);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 172px;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0px;
  margin: 0px 5px 0px 0px !important;
}

.upload-image .uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0;
}

.upload-image .image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.upload-image .edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}
