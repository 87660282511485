.inference-inputs {
  background-color: var(--neutral-2);
  border: 1px solid;
  border-color: var(--neutral-5);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 16px;
  position: relative;
  width: 100%;
  height: 100%;
}

.inference-options {
  flex: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  min-height: 0;
  border-bottom: 1px solid var(--Neutral-5, #D9D9D9);
}

.inference-image {
  height: 203px;
}

.inference-options::-webkit-scrollbar {
  width: 5px;
}

.inference-options::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.inference-options::-webkit-scrollbar-thumb {
  background: #888;
}

.inference-options::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.inference-box {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  /* width: 98%; */
}

.function-box {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  /* width: 100%; */
}

.category-input.ant-input {
  border-radius: 0px;
  height: 40px;
}

.select-instance {
  align-self: stretch;
  height: 40px;
  width: 100%;
}

.number-slider {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  /* width: 100%; */
}

.credit-balance {
  color: transparent;
  font-family: var(--h2-medium-font-family);
  font-size: var(--h2-medium-font-size);
  font-style: var(--h2-medium-font-style);
  font-weight: var(--h2-medium-font-weight);
  letter-spacing: var(--h2-medium-letter-spacing);
  line-height: var(--h2-medium-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
  margin: 0;
}

.remained-balance {
  color: #262626;
  font-family: var(--h2-medium-font-family);
  font-size: var(--h2-medium-font-size);
  font-style: var(--h2-medium-font-style);
  font-weight: var(--h2-medium-font-weight);
  letter-spacing: var(--h2-medium-letter-spacing);
  line-height: var(--h2-medium-line-height);
}

.total-balance {
  color: #bfbfbf;
  font-family: var(--h2-medium-font-family);
  font-size: var(--h2-medium-font-size);
  font-style: var(--h2-medium-font-style);
  font-weight: var(--h2-medium-font-weight);
  letter-spacing: var(--h2-medium-letter-spacing);
  line-height: var(--h2-medium-line-height);
}

.button-box {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
  height: 40px;
}

.button-group-custom {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  padding-left: 0;
  width: 100%;
}