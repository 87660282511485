.box {
    height: 19px;
    width: 89px;
    padding: 0px 24px;
}

.box .mixme {
    height: 19px;
    left: 0;
    top: 0;
    width: 89px;
}
