.signup {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.signup .login-wrapper {
  background-color: #ffffff;
  height: auto;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup .div-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 180px;
  justify-content: center;
  /* left: 540px; */
  position: relative;
  margin: auto;
  width: 368px;
  /* top: 73px; */
}

.signup .div-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.signup .logo {
  height: 50px;
  position: relative;
  width: 160px;
}

.login .page-title {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.signup .text-wrapper-2 {
  color: var(--character-secondary-45);
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.signup .form {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 22px;
  position: relative;
  width: 100%;
}

.signup .text-text-instance {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.signup .design-component-instance-node {
  color: var(--charactertitle-85) !important;
  font-family: var(--h5-medium-font-family) !important;
  font-size: var(--h5-medium-font-size) !important;
  font-style: var(--h5-medium-font-style) !important;
  font-weight: var(--h5-medium-font-weight) !important;
  letter-spacing: var(--h5-medium-letter-spacing) !important;
  line-height: var(--h5-medium-line-height) !important;
}

.signup .inputs {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 100%;
}

.signup .error-message {
  color: #ff0000;
  font-family: Arial, sans-serif;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  text-align: left;
}

.signup .error-placeholder {
  color: #ff0000;
  font-family: Arial, sans-serif;
  font-size: 12px;
  height: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
}

.signup .input-large-normal-false-true-false-false-false {
  height: 40px !important;
  width: 360px !important;
}

.signup .input-large-normal-instance {
  flex: 1 !important;
  width: unset !important;
}

.signup .input-large-normal-false-true-false-false-false-instance {
  height: 14px !important;
  left: 2px !important;
  top: 1px !important;
  width: 13px !important;
}

.signup .group {
  height: 24px;
  position: relative;
  width: 360px;
}

.signup .checkbox-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1px !important;
}

.signup .button-instance {
  left: 197px !important;
  padding: unset !important;
  position: absolute !important;
  top: 0 !important;
}

.signup .button-2 {
  height: unset !important;
  width: fit-content !important;
}

.signup .div-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 22px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.signup .button-3 {
  flex: 0 0 auto !important;
  height: 40px !important;
}

.signup .footer {
  align-items: center;
  background-color: transparent;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.signup .input-prefix-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
}

.signup .input-prefix {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.signup .p {
  color: var(--character-secondary-45);
  font-family: var(--body-regular-font-family);
  font-size: var(--body-regular-font-size);
  font-style: var(--body-regular-font-style);
  font-weight: var(--body-regular-font-weight);
  letter-spacing: var(--body-regular-letter-spacing);
  line-height: var(--body-regular-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
