.dragdrop {
    align-items: center;
    /* background-color: var(--character-secondary-45); */
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    padding: 59px 51px;
    position: relative;
}

.dragdrop .text-wrapper {
  color: var(--character-primaryinverse);
  font-family: var(--body-regular-font-family);
  font-size: var(--body-regular-font-size);
  font-style: var(--body-regular-font-style);
  font-weight: var(--body-regular-font-weight);
  letter-spacing: var(--body-regular-letter-spacing);
  line-height: var(--body-regular-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
