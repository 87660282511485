.global-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0px;
  background-color: var(--primary-7);
  box-shadow: var(--border-divider-divider);
  height: 48px;
  position: relative;
  width: 100vw;
}

.global-header .logo {
  position: relative;
  width: 67.5px;
}

.global-header .toolbar {
  flex: 0 0 auto !important;
  justify-content: flex-end !important;
}

.global-header .icon-wrapper-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  padding: 0px 12px !important;
}

.global-header .question-circle {
  height: 14px !important;
  object-fit: cover !important;
  position: relative !important;
  width: 14px !important;
}

.global-header .profile {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  padding: 0px 24px;
  cursor: pointer;
}

.global-header .profile:hover {
  cursor: pointer;
}

.global-header .icon-wrapper-2 {
  display: flex !important;
  padding: 0px 8px 0px 0px !important;
  width: 32px !important;
}

.global-header .design-component-instance-node-2 {
  flex: 0 0 auto !important;
}

.global-header .text-text-instance {
  color: var(--character-primaryinverse) !important;
}


.global-header .right-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
}

.global-header .right-group .question-icon {
  margin-right: 8px;
}

.global-header .user-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.global-header .user-name {
  margin-left: '8px';
  cursor: 'pointer';
  color: var(--character-primaryinverse);
  font-family: var(--h5-medium-font-family);
  font-size: var(--h5-medium-font-size);
  font-style: var(--h5-medium-font-style);
  font-weight: var(--h5-medium-font-weight);
  letter-spacing: var(--h5-medium-letter-spacing);
  line-height: var(--h5-medium-line-height);
  display: flex;
  align-items: center;
  color: white;
}

.global-header .drop-down .ant-dropdown-menu {
  border-radius: 0px;
}

.global-header .down-icon {
  width: 16px;
  height: 24px;
}