.login {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login .login-wrapper {
  background-color: #ffffff;
  height: auto;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login .div-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 180px;
  justify-content: center;
  /* left: 540px; */
  position: relative;
  margin: auto;
  /* top: 73px; */
}

.login .div-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.login .text-wrapper-2 {
  color: var(--character-secondary-45);
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.login .page-title {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.login .form {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 22px;
  position: relative;
  width: 310px;
}

.login .text-text-instance {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.login .design-component-instance-node {
  color: var(--charactertitle-85) !important;
  font-family: var(--h5-medium-font-family) !important;
  font-size: var(--h5-medium-font-size) !important;
  font-style: var(--h5-medium-font-style) !important;
  font-weight: var(--h5-medium-font-weight) !important;
  letter-spacing: var(--h5-medium-letter-spacing) !important;
  line-height: var(--h5-medium-line-height) !important;
}


.login .input-box {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 28px;
  position: relative;
  width: 100%;
}

.login .input-box .input {
  height: 40px;
}

.login .input-large-normal-false-true-false-false-false {
  width: 360px !important;
}

.login .group {
  display: flex;
  justify-content: space-between;
  height: 24px;
  position: relative;
  width: 100%;
  height: 40px;
  padding: 20px 0px;
}

.login .checkbox-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1px !important;
}

.login .remember-me {
  display: inline-flex;
  align-items: center;
}

.login .forgot-password {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: var(--Primary-6, #1890FF);
  text-align: center;

  /* H5/regular */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.login .button-instance {
  left: 197px !important;
  padding: unset !important;
  position: absolute !important;
  top: 0 !important;
}

.login .button-2 {
  height: unset !important;
  width: fit-content !important;
}

.login .login-buttons {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 22px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.login .button-3 {
  flex: 0 0 auto !important;
  height: 40px !important;
}

.login .footer {
  align-items: center;
  background-color: transparent;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.login .input-prefix-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
}

.login .input-prefix {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.login .p {
  color: var(--character-secondary-45);
  font-family: var(--body-regular-font-family);
  font-size: var(--body-regular-font-size);
  font-style: var(--body-regular-font-style);
  font-weight: var(--body-regular-font-weight);
  letter-spacing: var(--body-regular-letter-spacing);
  line-height: var(--body-regular-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
