.confirm-message {
    align-items: flex-end;
    background-color: var(--conditional-pop-over);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 416px;
    gap: 24px;
}

.confirm-message .confirm-box {
    display: flex;
    padding: 32px 32px 24px 32px;
    gap: 24px;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
}

.confirm-message .title-box {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 16px;
}

.confirm-message .exclamation-circle {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
}

.confirm-message .message-title {
    gap: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
}

.confirm-message .message-title .title {
    align-self: stretch;
    color: var(--charactertitle-85);
    font-family: var(--h5-medium-font-family);
    font-size: var(--h5-medium-font-size);
    font-style: var(--h5-medium-font-style);
    font-weight: var(--h5-medium-font-weight);
    letter-spacing: var(--h5-medium-letter-spacing);
    line-height: var(--h5-medium-line-height);
    margin-top: -1px;
    position: relative;
    text-align: left;
}

.confirm-message .message-title .content {
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

    font-family: var(--body-regular-font-family);
    font-size: var(--body-regular-font-size);
    font-style: var(--body-regular-font-style);
    font-weight: var(--body-regular-font-weight);
    line-height: var(--body-regular-line-height);

    text-align: left;
}

.confirm-message .message-title .email {
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

    font-family: var(--h5-regular-font-family);
    font-size: var(--h5-regular-font-size);
    font-style: var(--h5-regular-font-style);
    font-weight: 700;
    line-height: var(--h5-regular-line-height);

    text-align: left;
}

.confirm-message .button-group {
    align-self: stretch;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.confirm-message .ok-button.ant-btn {
  border-radius: 0px;
}
