.logo {
    height: 67px;
    width: 196px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo .text-wrapper {
    color: #262626;
    font-family: "Trispace", Helvetica;
    font-size: 56px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 67.2px;
    position: relative;
    white-space: nowrap;
}
