.reset-message {
    align-items: flex-end;
    background-color: var(--conditional-pop-over);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 416px;
}

.invalid-email {
  border-color: red;
  border-radius: 0px;
  height: 40px;
}

.reset-message  .title-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.reset-message .title-box .title {
    align-self: stretch;
    color: var(--charactertitle-85);
    font-family: var(--h5-medium-font-family);
    font-size: var(--h5-medium-font-size);
    font-style: var(--h5-medium-font-style);
    font-weight: var(--h5-medium-font-weight);
    letter-spacing: var(--h5-medium-letter-spacing);
    line-height: var(--h5-medium-line-height);
    margin-top: -1px;
    position: relative;
    padding: 16px 24px;
    align-self: start;
}

.reset-message .email-box {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.reset-message .email-input {
    align-self: stretch;
    display: flex;
    flex-direction: column;
}

.ant-input-lg {
  border-radius: 0px;
}

.reset-message .button-group {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    padding: 10px 16px;
}

.reset-message .cancel-button.ant-btn {
  border-radius: 0px;
}

.reset-message .ok-button.ant-btn {
  border-radius: 0px;
}